@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #21242d;
  font-family: manrope;
}

.bg-\[\#343434\] {
  --tw-bg-opacity: 1;
  background-color: #C69AFF;
}


.min-h-screen {
  min-height: 100vh;
  background-color: #220049;
}

.text-\[\#1FF6D0\] {
  --tw-text-opacity: 1;
  color: #f79eff;
}


.bg-\[\#21242D\] {
  --tw-bg-opacity: 1;
  background-color: #220049;
}


option {
    color: #000 !important;
}
:focus-visible {
  outline: 0;
}
.table-container {
    width: 100%;
    display:block;
    justify-content: center;
    padding: 20px;
    background-color: #C69AFF;
  }
  
  .crypto-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    color: rgb(2, 2, 2);
  }

  .inside-tab td {
    border: 0 !important;
    padding: 0 !important;
    padding: 15px !important;
    background-color: #f5f5f5 !important;
  }
  
  .crypto-table td {
    font-family: manrope;
    background-color: #f5f5f5;
    border-right: 2px solid #ffffff;
    border-bottom: 0 !important;
    border-left: 0;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
    color: #000000;
  }
  .crypto-table th {
    background-color: #3c0058;
    font-family: manrope;
    color: #ffffff;
    padding: 15px;
    border-right: 0px solid #ffffff;
    font-weight: 300;
    text-transform: uppercase;
  }
  
  thead {
    background-color: #2b2b2b;
  }

  .bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: #C69AFF;
}
  
 
  .current-price {
    font-size: 16px;
    margin: 0;
    float: left;
  }
  
  .price-difference {
    font-size: 14px;
    float: right;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }
  
  .buy-button, .sell-button {
    padding: 5px 10px;
    font-size: 14px;
    margin: 0 5px;
    border: none;
    border-radius: 3px;
  }
 
  .crypto-table tbody tr {
    background-color: #1b1c1f;
  }
  
  .crypto-table tbody tr:nth-child(even) {
    background-color: #1b1c1f;
  }


.crypto-table th:first-child {
  font-family: manrope;
    border: 0;
    background-color: #8c04c2;
    color: #F6F6F6;
    font-weight: 300;
    text-align: left !important;
}

.crypto-table td:first-child {
    text-align: left !important;
    background-color: #21242d;
    color: #ffffff;
    border-left: 0;
    border-right: 0;
    padding: 15px !important;
}

.crypto-table .last-border:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
td.thin-border {
  border-right: 1px solid #3E3E3E !important;

}

.transac-table td svg {
  display: inline-block;
}

@media (max-width: 767px) {
  .mobile-right {
    width: calc(100% - 80px);
  }
  .mobile-left {
    width: 80px;
  }
  .mobile-left li span:last-child {
    display: none;
  }
  .grid-mob {
      grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .tooltip {
    top: 50% !important;
    left: calc(50% - 40px) !important;
    transform: translate(-50%) !important;
  }
}